<template>
  <div class="activity-edit">
    <div class="activity-create-wrapper manage-wrapper">
      <div class="activity-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑活动" name="first">

            <el-form ref="form" :model="form" label-width="180px" :rules="rules">
              <!-- 活动名称 -->
              <el-form-item label="活动名称：" prop="name">
                <el-input v-model.trim="form.name" maxlength="30" show-word-limit style="width:555px"></el-input>
              </el-form-item>
              <!-- 活动封面 -->
              <el-form-item label="活动封面：">
                <UploadCover @uploadCover="handleCoverChange" :xcover="form.cover"></UploadCover>
              </el-form-item>
              <!-- banner图 -->
              <el-form-item label="banner图：">
                <UploadCover @uploadCover="handleBnnerChange" :xcover="form.banner"></UploadCover>
              </el-form-item>
              <!-- 报名时间段 -->
              <el-form-item label="活动时间：" :required="true" prop="activityTime">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.activityTime"
                  :picker-options="pickerOptions" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="handleTimeChang(form.activityTime)">
                </el-date-picker>
              </el-form-item>
              <!-- 活动类型 -->
              <el-form-item label="活动类型：" prop="activityType" required>
                <el-radio-group v-model="form.activityType">
                  <el-radio :label="2">合作学习墙</el-radio>
                  <el-radio :label="0">大赛类</el-radio>
                  <el-radio :label="1" disabled>教研类</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 活动对象 -->
              <!-- <el-form-item label="活动对象：" prop="activityTarget" required>
                <el-checkbox-group v-model="form.activityTarget">
                  <el-checkbox :label="0">老师</el-checkbox>
                  <el-checkbox :label="1">学生</el-checkbox>
                  <el-checkbox :label="2">家长</el-checkbox>
                </el-checkbox-group>
              </el-form-item> -->
              <el-form-item label="学科：">
                <el-select v-model="form.subject" placeholder="请选择学科" clearable>
                  <el-option v-for="item in subjectList" :key="item.value" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 所属项目 -->
              <el-form-item label="用户组：" prop="userGroup" required>
                <el-cascader :options="userGroupOptionList" :props="{ multiple: true, value: 'id',emitPath:false}"
                  clearable filterable v-model="form.userGroup" @change="userGroupChange" collapse-tags
                  placeholder="请选择用户组">
                </el-cascader>
              </el-form-item>
              <!-- <el-form-item label="负责人：">
                <el-select v-model="form.initiatorId" placeholder="请选择负责人" clearable filterable
                  :disabled="initiatorDisabled">
                  <el-option v-for="item in initiatorList" :key="item.value" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <!-- 活动简介 -->
              <el-form-item label="活动简介：">
                <el-input type="textarea" class="textarea" :rows="3" maxlength="300" show-word-limit style="width:800px"
                  v-model="form.introduction" resize="none"></el-input>
              </el-form-item>
              <!-- 活动规则 -->
              <el-form-item label="活动规则：" class="tinymce" required>
                <upload @uploadFile="handleFileChange" :name="'identifier'" :text="'上传活动规则'" :list="rulesFile" />
              </el-form-item>
              <!-- 课程资源 -->
              <!-- <el-form-item label="课程资源：" v-if="form.activityType == 0">
                <el-select v-model="form.courseIds" placeholder="请选择" multiple>
                  <el-option v-for="item in courseList" :key="item.value" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <el-row v-if="form.activityType == 0">
                <el-form-item label="共读课程资源1：" prop="gradeIds">
                  <el-input v-model.trim="couresTitle" maxlength="30" show-word-limit style="width:350px"
                    placeholder="请输入共读资源圈标题">
                  </el-input>
                  <i class="el-icon-plus" @click="addCourse"></i>
                </el-form-item>
                <el-form-item prop="courseIds" style="display:inline-block">
                  <el-select v-model="courseIds" placeholder="请选择课程" multiple>
                    <el-option v-for="item in courseList" :key="item.value" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="flex-layout" v-for="(item,index) in counterList" :key="item">
                  <publicCourse @change="handleCourseIds" :count="item" :index="index" :info="publicCourseMap[item]">
                  </publicCourse>
                  <span class="delete-range csp" @click.stop="deleteRange(index,item)">删除</span>
                </div>
              </el-row>
              <!-- 按钮 -->
              <el-form-item class="el-form-item-btns">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapState
  } from "vuex";
  import upload from './components/upload-rule.vue';
  import publicCourse from './components/public-course.vue';
  export default {
    name: "addclass",

    data() {
      return {
        activeName: "first",
        form: {
          projectId: undefined,
          id: undefined,
          creater: undefined,
          name: '',
          cover: '',
          banner: '',
          // activityTime: '',
          activityBeginTime: '',
          activityEndTime: '',
          activityType: undefined,
          subject: '',
          userGroup: null, //用户组id
          initiatorId: undefined,
          introduction: null, //用户组id
          detail: null, //用户组id
          // activityTarget: [0],
        },
        rules: {
          name: [{
            required: true,
            message: "请输入活动名称",
            trigger: "blur"
          }],
          activityTarget: [{
            required: true,
            message: "请选择活动对象",
            trigger: "change"
          }],
          activityTime: [{
            required: true,
            message: "请选择活动时间",
            trigger: ['blur', 'change']
          }],
          userGroup: [{
            required: true,
            message: "请选择用户组",
            trigger: ['blur', 'change']
          }]
        },
        initiatorDisabled: false, // 是否可选负责人
        userGroupOptionList: [], //用户组选项列表
        initiatorList: [], // 负责人列表
        courseList: [], //课程列表
        subjectList: [], // 学科列表
        rulesFile: [], // 活动规则PDF文件
        pickerOptions: { //今天以前（不含今天）禁用
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },
        couresTitle: '',
        courseIds: [],
        counter: 0,
        counterList: [],
        publicCourseMap: {},

      };
    },
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject,
      })
    },
    components: {
      upload,
      publicCourse
    },
    watch: {},
    methods: {
      addCourse() {
        this.counter++
        this.counterList.push(this.counter);
      },
      // 删除选中的共读课程资源
      deleteRange(i, item) {
        this.counterList.splice(i, 1)
        delete this.publicCourseMap[item]
        console.log('publicCourseMap', this.publicCourseMap)
      },
      // 选择共读课程返回数据
      handleCourseIds(data) {
        // console.log('共读返回数据', data);
        this.publicCourseMap[data.index] = data.data
        console.log('publicCourseMap', this.publicCourseMap)
      },
      // 封面md5赋值
      handleCoverChange(data) {
        this.form.cover = data.md5
      },
      // banner md5赋值
      handleBnnerChange(data) {
        this.form.banner = data.md5
      },
      // 活动时间赋值
      handleTimeChang(val, type) {
        this.form.activityBeginTime = val[0];
        this.form.activityEndTime = val[1];
        // console.log(this.form);
      },
      // 活动规则md5赋值
      handleFileChange(data) {
        // console.log(data);
        this.form.detail = data.fileMD5List[0];
      },
      // 取消编辑活动
      cancel() {
        this.$confirm("确认取消编辑活动？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      },
      // 用户组选中变更
      userGroupChange(val) {
        // console.log(val)
        if (!val || JSON.stringify(val) == '[]') {
          this.initiatorList = [];
          this.form.initiatorId = undefined;
          this.initiatorDisabled = true;
          return;
        }
        this.initiatorDisabled = false;
        this.getInitiatorList();
      },
      // 验证并提交表单
      onSubmit() {
        console.log(this.form);
        this.$refs['form'].validate((valid) => {
          if (!valid) return;
          if (!this.form.detail) {
            this.$message({
              type: 'error',
              message: '请上传活动规则'
            })
            return;
          }
          // 整理共读资源圈数据 start
          let courseInfos = [];
          let courses = [];
          if (this.courseIds.length > 0) {
            this.courseIds.map(item => {
              courses.push({
                id: item
              })
            })
          }
          let zeroData = {
            title: this.couresTitle,
            courses: courses
          }
          courseInfos.push(zeroData);
          if (this.publicCourseMap.length > 0) {
            this.publicCourseMap.map(item => {
              courseInfos.push(item);
            })
          }
          for (const key in this.publicCourseMap) {
            courseInfos.push(this.publicCourseMap[key]);
          }
          this.$set(this.form, 'courseInfos', courseInfos)
          // 整理共读资源圈数据 end
          this.commitForm();
        })
      },
      // 创建活动请求
      async commitForm() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        // this.$delete(this.form, 'activityTime');
        let resData = await this.$Api.Course.editActivity(this.form)
        // console.log(resData);
        if (resData.data.code === 200 && resData.data.data === 1) {
          DoUpElement.notify(this, 'success', "编辑成功", 1000, () => {
            this.$router.go(-1);
            loading.close();
          })
        } else if (resData.code === 500) {
          this.$message({
            type: 'error',
            message: '填写内容错误'
          })
          loading.close();
        } else {
          loading.close();
          this.$message({
            type: 'error',
            message: resData.data.msg
          })
          loading.close();
        }
      },
      // 获取活动详情信息
      async getActivityDetail() {
        let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
        // console.log('表单信息 获取', resData);
        // this.form = resData.data;
        for (const key in this.form) {
          this.form[key] = resData.data[key];
        }
        // console.log('表单信息 遍历', this.form);
        this.$set(this.form, 'activityTime', [this.form.activityBeginTime, this.form.activityEndTime])
        this.$set(this.form, 'subject', Number(this.form.subject))
        this.initiatorDisabled = this.form.initiatorId ? false : true;
        this.rulesFile = [{
          filename: resData.data.detailFileName,
          identifier: resData.data.detail
        }]
        this.getInitiatorList();
        this.getSubjectList();

        if (this.form.activityType == 0) {
          this.getListForActivityCreate();
          this.counter = resData.data.courseInfos.length - 1;
          for (let i = 1; i <= this.counter; i++) {
            this.counterList.push(i);
          }
          // 第一个共读资源圈数据
          this.couresTitle = resData.data.courseInfos[0].title
          resData.data.courseInfos[0].courses.map(item => {
            this.courseIds.push(item.id)
          })
          // 其他共读资源圈数据
          resData.data.courseInfos.splice(0, 1)
          resData.data.courseInfos.map((item, index) => {
            this.publicCourseMap[index + 1] = {
              title: item.title,
              courses: item.courses
            }
          })
          console.log('this.publicCourseMap', this.publicCourseMap)

          // let getCourseList = this.getListForActivityCreate();
          // Promise.all([getCourseList]).then((values) => {
          //   // if (values.length > 0) {
          //   // }
          // });
          // this.$set(this.form, 'courseIds', [])
          // resData.data.courseInfos.map(item => {
          //   this.form.courseIds.push(item.id)
          // })
        }
        console.log('表单信息 遍历', this.form);
      },
      // 获取课程学科列表
      async getSubjectList() {
        let resData = await this.$Api.Course.SubjectList();
        this.subjectList = resData.data;
        // Vue.set(this.form, 'subject', resData.data[0].id);
      },
      // 获取用户组列表
      async getUserGroupOptionList() {
        let resData = await this.$Api.Form.getUserGroup(this.currentProject.id)
        // console.log('用户组列表', resData)
        this.userGroupOptionList = resData.data;
      },
      // 获取负责人列表
      async getInitiatorList() {
        let params = {
          userGroupIds: this.form.userGroup,
        };
        // console.log(params);
        let resData = await this.$Api.Course.TeacherList(params);
        // console.log('负责人列表', resData);
        this.initiatorList = resData.data
      },
      // 获取大赛类活动绑定课程列表
      async getListForActivityCreate() {
        let params = {
          projectId: this.currentProject.id
        }
        let resData = await this.$Api.Activity.getListForActivityCreate(params)
        console.log('课程列表', resData.data);
        this.courseList = resData.data
        return new Promise((resolve, reject) => {
          resolve(resData)
        })
      },


      // 页面初始化
      init() {
        scroll(0, 0)
        this.getActivityDetail();
        this.form.id = this.$route.params;
        this.form.projectId = this.currentProject.id;
        this.getUserGroupOptionList();

      }
    },
    mounted() {
      this.init()
    },
    activated() {
      this.init()
    },
    deactivated() {
      this.form = {}
    }
  };
</script>

<style lang="less" scoped>
  .activity-edit {
    .el-icon-plus {
      color: #333;
      font-size: 14px;
      font-weight: 600;
      margin-left: 15px;
      cursor: pointer;
      padding: 2px;
      border: 1px solid transparent;
      border-radius: 50%;
      transition: 1s all;

      &:hover {
        border: 1px solid #333;
      }
    }

    .delete-range {
      color: #508EF9;
      margin-left: 17px;
      align-self: flex-start;
      margin-top: 10px;

      &:hover {
        color: rgba(80, 142, 249, .7);
      }
    }
  }
</style>